<!-- eslint-disable vue/no-unused-vars -->
<template>
  <menikheim-layout>
    <template #content>
      <h1><span class="text-highlight">U</span>nsere Leistungen im Außenbereich</h1>
      <div class="container">
        <div class="row">


            <LeistungComponent v-for="(leistung, key) in leistungen" :key="leistung.caption" :leistung="leistung" :last="isLast(key)" />

        </div>
      </div>
    </template>
  </menikheim-layout>
</template>

<script>
import MenikheimLayout from "@/layouts/MenikheimLayout.vue";
import LeistungComponent from "@/components/LeistungComponent.vue";

export default {
  name: "AussenLeistungenPage",
  components: {
    MenikheimLayout,
    LeistungComponent
  },
  props: {
    mandantId: {
      type: Number,
      default: 0,
    },
  },
  computed: {
    getArticles() {
      return this.$store.getters.articles;
    },

  },
  methods: {
    isLast(i) {
      return i != Object.keys(this.leistungen).length - 1;
    }
  },
  data() {
    return {
      publicPath: process.env.BASE_URL,
      leistungen: [
        {
          caption: "Außenputze",
          image: "aussenputz.jpg",
          content: "Grund- und Oberputze, Dämmputze"
        },{
          caption: "Wärmedämm-Verbundsysteme",
          image: "daemmung.jpg",
          content: `Polystyrol-Hartschaum-Platten, Mineral- oder Steinwolle-Platten<br />Holzfaser Dämmstoffplatten, Korkdämmstoff-Platten`
        },{
          caption: "Malerarbeiten",
          image: "malerarbeiten_aussen.jpg",
          content: "Fassadenanstriche\nLackier- und Lasierarbeiten für Holzfenster, Dachgesimse, Holzfassaden, Geländer..."
        },{
          caption: "Gerüstbau",
          image: "geruestbau.jpg",
          content: "Fassadengerüste, Fahrbare Gerüste, Flächengerüste,Treppentürme, Schutzgerüste..."
        },
      ]
    };
  },
};
</script>

<style scoped>

</style>
