<template>
   <menikheim-layout>
    <template #content>
      <div class="container">
    <div class="row">
      <div class="col-12 mb-5">
        <h1><span class="text-highlight">JMS</span> Menikheim GmbH & Co. KG</h1>
      </div>
      <div class="col-4">
        <h3><span class="text-highlight">A</span>nschrift</h3>
        <p>Brühlstrasse 20<br>73635 Rudersberg</p>
        <h3><span class="text-highlight">T</span>elefon / Telefax</h3>
        <p>Telefon: 07183 / 8428<br>Telefax: 07183 / 7489</p>
        <h3><span class="text-highlight">E</span>-Mail</h3>
        <p><a href="mailto:info@menikheim-stuck.de">info@menikheim-stuck.de</a></p>
      </div>
      <div class="col-8">
        <div class="container">
          <div class="row mt-5">
            <div class="col-6">
              
            </div>
            <div class="col-6">
              <h3>Frau <span class="text-highlight">A</span>ngela Brose</h3>
              <p>Geschäftszeiten: Mo-Fr 7.30 bis 17.00 Uhr</p>
              <p>Telefon: 07183 / 93 795 - 83<br /><a href="mailto:info@menikheim-stuck.de">info@menikheim-stuck.de</a></p>
            </div>
          </div>
          <div class="row">
            <div class="col-6">
              <img src="@/assets/images/imgl0021_fp-2012.jpg" alt="Birka Krause" classes="rounded"/>
            </div>
            <div class="col-6">
              <h3>Frau <span class="text-highlight">B</span>irka Krause</h3>
              <p>Geschäftszeiten: Di-Fr 7.30 bis 16.30 Uhr</p>
              <p>Telefon: 07183 / 93 795 - 82<br /><a href="mailto:info@menikheim-stuck.de">info@menikheim-stuck.de</a></p>
            </div>
          </div>
          <div class="row mt-5">
            <div class="col-6">
              
            </div>
            <div class="col-6">
              <h3>Frau <span class="text-highlight">V</span>ivian Sombrutzki<br /><span class="text-highlight" style="font-size:0.6em; font-style: italic;">Malermeisterin</span></h3>
              <p>Geschäftszeiten: Mo-Fr 7.30 bis 16.30 Uhr</p>
              <p>Telefon: 07183 / 93 795 - 84<br /><a href="mailto:info@menikheim-stuck.de">info@menikheim-stuck.de</a></p>
            </div>
          </div>
          <div class="row mt-5">
            <div class="col-6">
              
            </div>
            <div class="col-6">
              <h3>Frau <span class="text-highlight">S</span>ebastian Brose<br /><span class="text-highlight" style="font-size:0.6em; font-style: italic;">Vorarbeiter im Stuckateurhandwerk</span></h3>
              <p>Geschäftszeiten: Mo-Fr 7.30 bis 16.30 Uhr</p>
              <p>Telefon: 07183 / 93 795 - 81<br /><a href="mailto:info@menikheim-stuck.de">info@menikheim-stuck.de</a></p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  </template>
  </menikheim-layout>
</template>

<script>
import MenikheimLayout from "@/layouts/MenikheimLayout.vue";

export default {
  name: "KontaktPage",
  components: {
    MenikheimLayout
  },
}
</script>

<style scoped>

</style>