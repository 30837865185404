<template>
  <div class="header">
    <div class="shaddow  d-none d-md-block"></div>
    <div class="top-slider">
      <carousel :items-to-show="1" :wrapAround="true" :autoplay="8000" :pauseAutoplayOnHover="true">
        <slide v-for="slide in carouselElements" :key="slide" style="width:100%">
          <img :src="slide.image" style="height:500px;object-fit: cover;width:100%;" />
          <div v-if="slide.showS" style="position:absolute; left:50px;height:500px;width:100%;">
            <div style="background-color:rgb(var(--color-rot)); position:absolute;left:15%;top:30%;padding-top:5px;padding-bottom:5px;padding-left:10px;padding-right:10px;border-radius: 5px;"
            v-html="slide.addText"></div>
            <div style="background-color:rgb(var(--color-grau)); position:absolute;left:35%;top:50%;padding-top:5px;padding-bottom:5px;padding-left:10px;padding-right:10px;border-radius: 5px;">Steffen Menikheim</div>
            <img src="@/assets/images/steffen-6e154203.png" alt="Steffen" style="position:absolute; left: 50%;top:40px;"/>
          </div>
        </slide>
        <template #addons>
          <navigation />
        </template>
      </carousel>
    </div>
    <div class="nav d-none d-md-block">
      <div class="background">
        <div id="logo">
          <img src="@/assets/images/logo_desk_dark.png" />
          <img src="@/assets/images/Schriftzug-Menikheim-by-JMS-dark.svg" width="220" style="left:19px;top:5px;" />
        </div>
        <div class="nav-slider">
          <ul>
            <NavigationElement v-for="navItem in navigationElements" :key="navItem.caption"
              :elementCaption="navItem.caption"
              :path="navItem.path" :activeCaption="getActiveCaption()"
              @change-component="changeComponent"/>
          </ul>
          <div class="nav_active"></div>
        </div>
      </div>
    </div>
    <div class="navbar navbar-expand-lg d-block d-md-none" style="border-bottom:3px rgb(var(--color-rot)) solid;">
      <a class="navbar-brand" href="#">
        <div id="logo" width="240px">
          <img src="@/assets/images/logo_desk_dark.png" />
          <img src="@/assets/images/Schriftzug-Menikheim-by-JMS-dark.svg" width="220" style="left:19px;top:5px;" />
        </div>
      </a>
      <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation" style="float:right" @click="toogleNav">
          <span class="" role="button" ><i class="fa fa-bars" aria-hidden="true" style="color:#e6e6ff"></i></span>
      </button>

      <div v-if="hideNavCaption" class="rounded navbar-nav mobile-nav">
        <NavigationElement v-for="navItem in navigationElements" :key="navItem.caption"
            :elementCaption="navItem.caption"
            :path="navItem.path" :activeCaption="getActiveCaption()"
            @change-component="changeComponent"/>
      </div>

    </div>
  </div>
  <div class="container content">
    <div class="row mt-5">
      <slot name="content"></slot>
    </div>
  </div>
  <div class="footer d-none d-md-block">
    <div class="foot_shadow">
      <img src="@/assets/images/shadow_content_bottom_dark.png" />
    </div>
  </div>
  <div class="footer2">
    <div class="container content">
      <div class="row">
        <div class="col-lg-3 col-mg-12">
          <h2><span style="color:rgb(var(--color-grau))">JMS</span> Menikheim<br>GmbH & Co. KG</h2>
          <p>Brühlstrasse 20<br>73635 Rudersberg</p>
        </div>
        <div class="col-lg-6 col-mg-12">
          <h3><span style="color:rgb(var(--color-grau))">U</span>nsere Leistungen</h3>
          <div class="container">
            <div class="row">
              <div class="col-6">
                <ul>
                  <li>Maler- &amp; Tapezierarbeiten</li>
                  <li>Wandmalerei</li>
                  <li>Trockenbau</li>
                  <li>Heizung und Sanitär</li>
                  <li>Bauleitung, Baukoordination, Planung</li>
                  <li>Umsetzung KfW/Bafa-F&ouml;rderprogramme</li>
                </ul>
              </div>
              <div class="col-6">
                <ul>
                  <li>Akustikbau</li>
                  <li>Spachteltechnik</li>
                  <li>Fassadenanstriche</li>
                  <li>Bodenbeläge</li>
                  <li>Altbausanierung</li>
                  <li>Energetische Gebäudesanierung <br />aus einer Hand</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-3 col-mg-12">
          <h3><span style="color:rgb(var(--color-grau))">K</span>ontakt</h3>
          <p>Telefon: 07183 / 93 795 - 83<br>Telefax: 07183 / 7489<br>E-Mail: info@menikheim-stuck.de</p>
          <p><a @click="changeComponent('/Impressum')" href="#">Impressum</a></p>
        </div>
      </div>
      <div class="row">
        <div class="col-12">
          <hr>
        </div>
      </div>
      <div class="row">
        <div class="col-12">
          <p>© 2024 JMS Menikheim GmbH & Co. KG</p>
          <p style="float:right">
            <a href="/">Zum Anfang der Seite</a>
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import 'vue3-carousel/dist/carousel.css';
import { Carousel, Slide, Navigation } from 'vue3-carousel';
import jQuery from "jquery";
import NavigationElement from "@/components/NavigationElement.vue";
const $ = jQuery;
window.$ = $;

export default {
  name: "MenikheimLayout",
  components: {
    NavigationElement,
    Carousel, Slide, Navigation
  },
  props: {
    leftColumnClass: {
      type: String,
      default: "",
    },
    rightColumnClass: {
      type: String,
      default: "col",
    },
    fullSize: {
      type: Boolean,
      default: true,
    },
  },
  emits: {

  },
  computed: {
    navigationElements() {
      return this.$store.getters.navigationElements("/");
    }
  },
  methods: {
    changeComponent(payload) {
      // this.toggleNav(false);
      this.$router.push({path: payload});
    },
    toogleNav(setValue = !this.hideNavCaption) {
      this.hideNavCaption = setValue;
    },
    getActiveCaption() {
      let activeNav;

      if(this.$route.path.substring(0, 9) == "/Projekt/") {
        activeNav = this.$store.getters.navigationElementsByPath("/Projekt/:projectid");
      } else if(this.$route.path.substring(0, "/Veranstaltung/".length) == "/Veranstaltung/") {
        activeNav = this.$store.getters.navigationElementsByPath("/Veranstaltung/:eventid");
      } else {
        activeNav = this.$store.getters.navigationElementsByPath(this.$route.path);
      }
      return activeNav.navSlider ? activeNav.navSlider : "Home";
    }
  },
  data() {
    return {
      hideNavCaption: false,
      carouselElements: [
      {
          image: require("@/assets/images/bg-2.jpg"),
          showS: true,
          addText: "<h3><span class='text-highlight-grau'>W</span>ir verstehen unser Handwerk,<br /> dafür stehe ich mit meinem Namen.</h3>"
        },{
          image: require("@/assets/images/bg-3.jpg"),
          showS: false,
          addText: "Blubber"
        },{
          image: require("@/assets/images/bg-4.jpg"),
          showS: false,
          addText: "Extra"
        },
      ]
    };
  },
  mounted() {
    var li_active = $(".nav-slider > ul > li.active, .hauptnavigation ul.level_1 > li.trail");
    var nav_active = $(".nav_active");

    nav_active.css("left", li_active.position().left).fadeIn(400);

    $(".nav-slider > ul > li").mouseenter(function () {
      //console.log("Mouseover " + $(this).text());
      var pos = $(this).position().left;
      $(".navigation > ul > li > a, .navigation > ul > li > span").css("color", "#000000");
      $(this).find("> a, > span").css("color", "#FFFFFF");
      nav_active.animate({ "left": pos }, 100);
    });
    $(".nav-slider").mouseleave(function () {
      var pos = li_active.position().left;
      $(".navigation > ul > li > a, .navigation > ul > li > span").css("color", "#000000");
      li_active.find("> a, > span").css("color", "#FFFFFF");
      nav_active.stop(true, true).animate({ "left": pos }, 100);
    });
  },
};
</script>

<style>
.header {
  width: 100%;
  max-height: 500px;
  position: relative;
}

.nav {
  top: 0px;
  left: 50%;
  margin-left: -480px;
  position: relative;
}

.nav .background {
  background: rgb(var(--color-background));
  position: absolute;
  width: 960px;
  height: 100px;
  z-index: 32;
  border-top: 5px rgb(var(--color-rot)) solid;
  bottom: 0px;
}

.content {
  width: 960px;
}

.capital {
  color: rgb(var(--color-rot));
}

.shaddow {
  position: absolute;
  z-index: 29;
  bottom: 0px;
  left: 50%;
  margin-left: -513px;
  background: url("@/assets/images/shadow_content_top.png") no-repeat top center;
  height: 100px;
  width: 1026px;
}

#logo {
  top: -68px;
  position: absolute;
  left: 7px;
}

#logo img {
  position: absolute;
}

.top-slider {
  max-height: 500px;
}

.nav-slider {
  display: block;
  position: absolute;
  right: 30px;
  top: -12px;
  overflow: visible;
}

.nav-slider>ul {
  list-style-type: none;
  position: relative;
  z-index: 32;
}

.nav-slider>ul>li {
  float: left;
  margin: 0;
  padding: 0;
  width: 123px;
  height: 110px;
  position: relative;
  line-height: 110px;
  text-align: center;
}

.nav_active {
  background: url(@/assets/images/nav_active_red.png) no-repeat;
  width: 123px;
  height: 110px;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 31;
  display: none;
  color: #FFFFFF;
}

.footer {
  width: 100%;
  position: relative;
  background-color: rgb(var(--color-rot));
  z-index: -2;
  padding-top: 120px;
}

.foot_shadow {
  position: absolute;
  left: 50%;
  margin-left: -513px;
  top: -50px;
  z-index: -1;
}

.footer2 {
  background-color: rgb(var(--color-rot));
  padding-top:20px;
}

.spinner-grow {
  color: rgba(#ff0000, var(--bs-text-opacity)) !important;
}

.mobile-nav {
  text-align: right;
  background: rgb(var(--color-rot));
  margin-left: 50px;
}

.mobile-nav > li {
  padding-right: 10px;
  padding-top: 5px;
}

</style>
