<!-- eslint-disable vue/no-unused-vars -->
<template>
  <menikheim-layout>
    <template #content>
      <h1><span class="text-highlight">U</span>nsere Leistungen im Innenbereich</h1>
      <div class="container">
        <div class="row">


            <LeistungComponent v-for="(leistung, key) in leistungen" :key="leistung.caption" :leistung="leistung" :last="isLast(key)" />

        </div>
      </div>
    </template>
  </menikheim-layout>
</template>

<script>
import MenikheimLayout from "@/layouts/MenikheimLayout.vue";
import LeistungComponent from "@/components/LeistungComponent.vue";

export default {
  name: "InnenLeistungenPage",
  components: {
    MenikheimLayout,
    LeistungComponent
  },
  props: {
    mandantId: {
      type: Number,
      default: 0,
    },
  },
  computed: {
    getArticles() {
      return this.$store.getters.articles;
    },

  },
  methods: {
    isLast(i) {
      return i != Object.keys(this.leistungen).length - 1;
    }
  },
  data() {
    return {
      publicPath: process.env.BASE_URL,
      leistungen: [
        {
          caption: "Farben und Natur im Einklang",
          image: "CapaGeo_innen.jpg",
          content: "Gesundheit und Umweltverantwortung sind vielen Kunden sehr wichtig. Sie achten auf verantwortungsvoll hergestellte Produkte und verbinden damit mehr Lebensqualität. Detaillierte Informationen finden Sie hier"
        },{
          caption: "Malerarbeiten",
          image: "malerei.jpg",
          content: "Sämtliche Maler-, Tapezier- und Lackierarbeiten Kreative Maltechniken"
        },{
          caption: "Trockenbau",
          image: "trockenbau.jpg",
          content: "Metallständer-Wände, Abgehängte Decken, Akustikkühldecken, Schall- & Brandschutz, Akustikbau, Kompletter Dachausbau"
        }
      ]
    };
  },
};
</script>

<style scoped>

</style>
