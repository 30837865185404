
const state = {
  events: [
    {
      eventid: "Knauf",
      caption: "Fortbildung bei Knauf",
      description: "Innenputz | Malerarbeiten im Innenbereich | Gerüstbau | Energetische Gebäudesanierung - Wärmedämmverbundsystem aus Polystyrol | Malerarbeiten und Farbgestaltung im Außenbereich",
      image: require("@/assets/images/knauf/teaser_ausflug_14.jpg"),
      content: "Fortbildung bei Knauf",
      showButton: true,
      link: "/Veranstaltung/Knauf",
      images: [
        {id:'1', src: require("@/assets/images/knauf/IMG_0842.jpg")},
        {id:'1', src: require("@/assets/images/knauf/IMG_0843.jpg")},
        {id:'1', src: require("@/assets/images/knauf/IMG_0846.jpg")},
        {id:'1', src: require("@/assets/images/knauf/IMG_0848.jpg")},
        {id:'1', src: require("@/assets/images/knauf/IMG_0860.jpg")},
        {id:'1', src: require("@/assets/images/knauf/IMG_0865.jpg")},
        {id:'1', src: require("@/assets/images/knauf/IMG_0875.jpg")},
        {id:'1', src: require("@/assets/images/knauf/IMG_0876.jpg")},
        {id:'1', src: require("@/assets/images/knauf/IMG_0891.jpg")},
        {id:'1', src: require("@/assets/images/knauf/IMG_0906.jpg")},
        {id:'1', src: require("@/assets/images/knauf/IMG_0911.jpg")},
        {id:'1', src: require("@/assets/images/knauf/IMG_0944.jpg")},
        {id:'1', src: require("@/assets/images/knauf/IMG_0955.jpg")},
        {id:'1', src: require("@/assets/images/knauf/IMG_0965.jpg")},
        {id:'1', src: require("@/assets/images/knauf/IMG_0993.jpg")},
        {id:'1', src: require("@/assets/images/knauf/IMG_0995.jpg")},
        {id:'1', src: require("@/assets/images/knauf/IMG_1000.jpg")},
        {id:'1', src: require("@/assets/images/knauf/IMG_1002.jpg")},
        {id:'1', src: require("@/assets/images/knauf/IMG_1009.jpg")},
        {id:'1', src: require("@/assets/images/knauf/IMG_1011.jpg")},
        {id:'1', src: require("@/assets/images/knauf/IMG_1015.jpg")},
        {id:'1', src: require("@/assets/images/knauf/IMG_1021.jpg")},
        {id:'1', src: require("@/assets/images/knauf/IMG_1024.jpg")},
        {id:'1', src: require("@/assets/images/knauf/IMG_1028.jpg")},
        {id:'1', src: require("@/assets/images/knauf/IMG_1032.jpg")},
      ]
    },
    {
      eventid: "Weihnachtsfeier2020",
      caption: "Weihnachtsfeier 2020",
      description: "Wir feiern gemeinsam im Hotel Sonne in Rudersberg.",
      image: require("@/assets/images/weihnachtsfeier/weihnachten_teaser.jpg"),
      content: "Wir feiern gemeinsam im Hotel Sonne in Rudersberg.",
      showButton: true,
      link: "/Veranstaltung/Weihnachtsfeier2020",
      images: [
        {id:'1', src: require("@/assets/images/weihnachtsfeier/weihnachtsfeier_01.jpg")},
        {id:'1', src: require("@/assets/images/weihnachtsfeier/weihnachtsfeier_02.jpg")},
        {id:'1', src: require("@/assets/images/weihnachtsfeier/weihnachtsfeier_03.jpg")},
        {id:'1', src: require("@/assets/images/weihnachtsfeier/weihnachtsfeier_04.jpg")},
        {id:'1', src: require("@/assets/images/weihnachtsfeier/weihnachtsfeier_05.jpg")},
        {id:'1', src: require("@/assets/images/weihnachtsfeier/weihnachtsfeier_06.jpg")},
        {id:'1', src: require("@/assets/images/weihnachtsfeier/weihnachtsfeier_07.jpg")},
        {id:'1', src: require("@/assets/images/weihnachtsfeier/weihnachtsfeier_08.jpg")},
        {id:'1', src: require("@/assets/images/weihnachtsfeier/weihnachtsfeier_09.jpg")},
        {id:'1', src: require("@/assets/images/weihnachtsfeier/weihnachtsfeier_10.jpg")},
        {id:'1', src: require("@/assets/images/weihnachtsfeier/weihnachtsfeier_11.jpg")},
        {id:'1', src: require("@/assets/images/weihnachtsfeier/weihnachtsfeier_12.jpg")},
        {id:'1', src: require("@/assets/images/weihnachtsfeier/weihnachtsfeier_13.jpg")},
        {id:'1', src: require("@/assets/images/weihnachtsfeier/weihnachtsfeier_14.jpg")},
        {id:'1', src: require("@/assets/images/weihnachtsfeier/weihnachtsfeier_15.jpg")},
        {id:'1', src: require("@/assets/images/weihnachtsfeier/weihnachtsfeier_16.jpg")},
      ]
    },
    {
      eventid: "Caparol",
      caption: "Fortbildung bei Caparol",
      description: "Fortbildung und Betriebsausflug nach Ober-Ramstadt.",
      image: require("@/assets/images/caparol/caparol_teaser.jpg"),
      content: "Fortbildung und Betriebsausflug nach Ober-Ramstadt.",
      showButton: true,
      link: "/Veranstaltung/Caparol",
      images: [
        {id:'1', src: require("@/assets/images/caparol/2013_06-Caparol-179.jpg")},
        {id:'1', src: require("@/assets/images/caparol/2013_06-Caparol-183.jpg")},
        {id:'1', src: require("@/assets/images/caparol/2013_06-Caparol-185.jpg")},
        {id:'1', src: require("@/assets/images/caparol/2013_06-Caparol-189.jpg")},
        {id:'1', src: require("@/assets/images/caparol/2013_06-Caparol-191.jpg")},
        {id:'1', src: require("@/assets/images/caparol/2013_06-Caparol-194.jpg")},
        {id:'1', src: require("@/assets/images/caparol/2013_06-Caparol-202.jpg")},
        {id:'1', src: require("@/assets/images/caparol/2013_06-Caparol-207.jpg")},
        {id:'1', src: require("@/assets/images/caparol/2013_06-Caparol-231.jpg")},
        {id:'1', src: require("@/assets/images/caparol/2013_06-Caparol-235.jpg")},
        {id:'1', src: require("@/assets/images/caparol/Birka-eigene-2013-1094.jpg")},
        {id:'1', src: require("@/assets/images/caparol/Birka-eigene-2013-1100.jpg")},
        {id:'1', src: require("@/assets/images/caparol/Birka-eigene-2013-1106.jpg")},
      ]
    },
    {
      eventid: "Baumit",
      caption: "Fortbildung bei BAUMIT",
      description: "Fortbildung und Betriebsausflug nach Hindelang.",
      image: require("@/assets/images/baumit/teaser_ausflug_2012.jpg"),
      content: "Fortbildung und Betriebsausflug nach Hindelang.",
      showButton: true,
      link: "/Veranstaltung/Baumit",
      images: [
        {id:'1', src: require("@/assets/images/baumit/SAM_0775.jpg")},
        {id:'1', src: require("@/assets/images/baumit/SAM_0777.jpg")},
        {id:'1', src: require("@/assets/images/baumit/SAM_0779.jpg")},
        {id:'1', src: require("@/assets/images/baumit/SAM_0780.jpg")},
        {id:'1', src: require("@/assets/images/baumit/SAM_0782.jpg")},
        {id:'1', src: require("@/assets/images/baumit/SAM_0784.jpg")},
        {id:'1', src: require("@/assets/images/baumit/SAM_0787.jpg")},
        {id:'1', src: require("@/assets/images/baumit/SAM_0794.jpg")},
        {id:'1', src: require("@/assets/images/baumit/SAM_0796.jpg")},
        {id:'1', src: require("@/assets/images/baumit/SAM_0814.jpg")},
        {id:'1', src: require("@/assets/images/baumit/SAM_0819.jpg")},
        {id:'1', src: require("@/assets/images/baumit/SAM_0832.jpg")},
        {id:'1', src: require("@/assets/images/baumit/SAM_0838.jpg")},
        {id:'1', src: require("@/assets/images/baumit/SAM_0842.jpg")},
        {id:'1', src: require("@/assets/images/baumit/SAM_0846.jpg")},
      ]
    },
    {
      eventid: "Sicherheitstraining",
      caption: "Sicherheitstraining am Hockenheimring",
      description: "Damit wir immer pünktlich und sicher bei Ihnen ankommen.",
      image: require("@/assets/images/hockenheim/hockenheim_uebersicht.jpg"),
      content: "Damit wir immer pünktlich und sicher bei Ihnen ankommen.",
      showButton: true,
      link: "/Veranstaltung/Sicherheitstraining",
      images: [
        {id:'1', src: require("@/assets/images/hockenheim/IMG_0040.jpg")},
        {id:'1', src: require("@/assets/images/hockenheim/IMG_0091.jpg")},
        {id:'1', src: require("@/assets/images/hockenheim/IMG_0211.jpg")},
        {id:'1', src: require("@/assets/images/hockenheim/IMG_0347.jpg")},
        {id:'1', src: require("@/assets/images/hockenheim/IMG_0359.jpg")},
        {id:'1', src: require("@/assets/images/hockenheim/IMG_0365.jpg")},
        {id:'1', src: require("@/assets/images/hockenheim/IMG_0556.jpg")},
        {id:'1', src: require("@/assets/images/hockenheim/IMG_0590.jpg")},
        {id:'1', src: require("@/assets/images/hockenheim/IMG_0613.jpg")},
        {id:'1', src: require("@/assets/images/hockenheim/IMG_0650.jpg")},
        {id:'1', src: require("@/assets/images/hockenheim/IMG_0654.jpg")},
        {id:'1', src: require("@/assets/images/hockenheim/IMG_0665.jpg")},
        {id:'1', src: require("@/assets/images/hockenheim/IMG_9897.jpg")},
        {id:'1', src: require("@/assets/images/hockenheim/IMG_9910.jpg")},
        {id:'1', src: require("@/assets/images/hockenheim/IMG_9946.jpg")},
        {id:'1', src: require("@/assets/images/hockenheim/IMG_9950.jpg")},
      ]
    },
  ]
};
const mutations = {

};
const actions = {

};
const getters = {
  getEvents: (state) => state.events,
  getEventById: (state) => (id) => state.events.find((e) => e.eventid == id),
};

const eventModule = {
  state,
  mutations,
  actions,
  getters,
};

export default eventModule;
