
const state = {
  articles: [
    {
      caption: "Bauen heißt Vertrauen",
      intro: "Wer sich entschließt, sein Haus umzubauen, es energetisch zu sanieren oder gar ein neues Zuhause zu schaffen, ist bei uns gut aufgehoben. Mit unserer Erfahrung aus über 40 Jahren wissen wir, was Bauherren bewegt. Deshalb bieten wir Ihnen zu unserem ",
      image:"bauen.jpg"
    },{
      caption: "Farben und Natur im Einklang",
      intro: "Gesundheit und Umweltverantwortung sind vielen Kunden sehr wichtig. Sie achten auf verantwortungsvoll hergestellte Produkte und verbinden damit mehr Lebensqualität. Die neuen CapaGeo Produkte, die wir als einer der ersten Malerbetriebe bevorzugt verwenden, bieten genau das jetzt auch für Innenfarben, Lacke und Holz-Öle.",
      image:"CapaGeo_neu.jpg"
    },{
      caption: "Erfolg ist Teamarbeit",
      intro: "Unsere Kunden können wir nur optimal bedienen, wenn wir Hand in Hand arbeiten. Deshalb fördern wir die Selbstverantwortung jedes Einzelnen und den Zusammenhalt im Menikheim-Team - ob bei Fortbildungen und Veranstaltungen, beim Fahrertraining oder beim Feiern.",
      image:"home_2.jpg"
    },{
      caption: "sDörfle in Kleinaspach",
      intro: "Im Jahr 2014 haben wir zusammen mit einem Architekten die Fassaden von elf Häusle geplant und ausgeführt. Hocheffiziente Steinlamellen-Dämmplatten in Verbindung mit historischen Baustoffen wie Standsteinen, Fachwerk und Holzfassaden verleihen dem Dörfle besonderen Charme.",
      image:"home_5.jpg"
    },
    {
      caption: "Wir suchen Sie!",
      intro: "Engagiert, motiviert, leistungsbereit. Wenn Sie diese Voraussetzungen erfüllen, sind wir an einer Zusammenarbeit mit Ihnen interessiert. Wir suchen laufend Mitarbeiter m/w und bilden Maler m/w/d und Bürokaufleute m/w/d aus. Melden Sie sich gerne! ",
      image: "home_2.jpg"
    },
    {
      caption: "Naturhäusle gefällig?",
      intro: "Mit unseren handerlesenen Partnern unterstützen wir Sie bei Ihrem Vorhaben Ihr Bauprojekt so natürlich wie möglich zu gestalten. Schauen Sie dazu einfach bei unseren Partnern vorbei.",
      image: "naturhaeusle.png"
    }
  ]
};
const mutations = {

};
const actions = {

};
const getters = {
  articles: (state) => state.articles,
};

const articleModule = {
  state,
  mutations,
  actions,
  getters,
};

export default articleModule;
